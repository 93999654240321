<template>
  <div style="width: 100%">
    <v-container>
      <v-card
        v-if="
          this.eventType.value == 'Budgivning' ||
          this.eventType.value == 'MinaSidor'
        "
        flat
        class="eventCard"
      >
        <b><span>Inloggningsstatistik</span></b>
        <br />
        <br />
        <table>
          <tr>
            <td class="tbHeader">Totalt antal inloggningar:</td>
            <td class="tbData">
              <v-skeleton-loader
                v-if="subLoader"
                type="table-cell"
              ></v-skeleton-loader>
              <span v-else> {{ totalLogins }}</span>
            </td>
            <td class="tbHeader">Totalt antal unika inloggningar:</td>
            <td class="tbData">
              <v-skeleton-loader
                v-if="subLoader"
                type="table-cell"
              ></v-skeleton-loader
              ><span v-else>{{ uniqueLogins }}</span>
            </td>
          </tr>
        </table>
      </v-card>
    </v-container>
    <!--   Events -->
    <v-container>
      <v-card flat class="eventCard">
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök"
            single-line
            hide-details
            style="margin: 10px; max-width: 250px"
          ></v-text-field>
        </v-row>
        <br />
        <span class="mainContainer">
          <span class="spinnerContent">
            <v-data-table
              class="row-pointer"
              :headers="headers"
              :search="search"
              :items="translatedEvents"
              density="compact"
              @click:row="rowClick"
              no-data-text="Inga händelser"
              :footer-props="{
                'items-per-page-text': 'Händelser per sida',
              }"
            ></v-data-table>
          </span>
          <div v-if="subLoader" class="spinnerOverlay" justify-content="center">
            <Spinner />
          </div>
        </span>
      </v-card>
    </v-container>

    <!-- dialog visa event mobilvy funkar ej obs -->
    <v-dialog
      v-model="eventDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="$vuetify.breakpoint.smAndUp ? 800 : '20vh'"
    >
      <v-card class="eventDialog">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b>Aktivitet:</b> {{ this.currentEvent.What }}
                <br />
              </span>
              <span class="boxText">
                <br />
                <b>Typ:</b> {{ this.currentEvent.Type }}
                <br />
              </span>
              <span v-show="this.currentEvent.Value" class="boxText">
                <br />
                <b>Vad:</b> {{ this.currentEvent.Value }}
                <br />
              </span>
              <br />
              <br />

              <br />
              <br />

              <span class="boxText">
                <b>Objekt:</b> {{ this.currentEvent.StreetAddress }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Part:</b> {{ this.currentEvent.ContactRole }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Namn:</b> {{ this.currentEvent.ContactName }}
              </span>
              <br />
              <br />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b> Datum:</b> {{ this.currentEvent.EventDateTime }}
                <br />
              </span>

              <br />
              <br />

              <span v-if="this.currentEvent.Extra" class="boxText">
                <br />
                <b>Info:</b> {{ this.currentEvent.Extra }}
                <br />
              </span>
              <span v-else>
                <br />
                <br />
                <br />
              </span>

              <br />
              <br />
              <span class="boxText">
                <b>Mäklare:</b> {{ this.currentEvent.BrokerName }}
              </span>
              <br />
              <br />
              <br />
              <br />

              <br />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeComment()" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import Spinner from '@/components/spinners/dualRing.vue';
import get from 'lodash/get';
export default {
  name: 'Events-results',
  props: { eventType: Object },
  mixins: [mixin],
  data() {
    return {
      headers: [
        { text: 'Datum', value: 'EventDate' },
        { text: 'Namn', value: 'ContactName' },
        { text: 'Objekt', value: 'StreetAddress' },
        { text: 'Typ', value: 'Type' },
        { text: 'Aktivitet', value: 'What' },
        { text: 'Värde', value: 'Value' },
      ],
      eventDialog: false,
      currentEvent: [],
      search: '',
      totalLogins: null,
      uniqueLogins: null,
      totalBuyers: null,
      uniqueBuyers: null,
      totalSellers: null,
      uniqueSellers: null,

      //loadingPage: true,
    };
  },

  computed: {
    subLoader() {
      return get(this.$store.state.azurestore, 'subLoader', false);
    },
    // Interested måste göras om om vi får in spekulanter se oneNote Ibland är interested ett ombud ibland fd budgivare
    events() {
      var events = get(this.$store.state.infopingstore, 'statsData.items', []);
      if (this.eventType.value == 'Budgivning') {
        events = events.filter(
          (a) =>
            a.ContactRole == 'InterestedBidder' || a.ContactRole == 'Interested'
        );
      }
      if (this.eventType.value == 'MinaSidor') {
        events = events.filter(
          (a) => a.ContactRole == 'Seller' || a.ContactRole == 'Buyer'
        );
      }
      return events;
    },
    translatedEvents() {
      return this.events.map((t) => {
        return {
          ...t,
          Type: this.translateType(t),
          What: this.translateWhat(t.What),
          Value: this.translateValue(t.Value),
          ContactRole: this.translateContactRole(t.ContactRole),
          EventDate: this.translateDate(t.EventDate),
          EventDateTime: this.translateDateTime(t.EventDate),
        };
      });
    },
  },
  watch: {
    events() {
      if (
        this.eventType.value == 'Budgivning' ||
        this.eventType.value == 'MinaSidor'
      )
        this.calculateStats();

      //this.loadingPage = false;
    },
  },
  components: { Spinner },
  mounted() {},
  methods: {
    translateType(item) {
      switch (item.Type) {
        case 'LoginLink':
          return 'Inloggningslänk';
        case 'AfterViewingSms':
          return 'Utskick efter visning';
        case 'RegistrationSms':
          return 'Utskick före visning';
        case 'BeforeRegSms':
          return 'Utskick före visning';
        case 'MinaSidor':
          if (item.ContactRole == 'InterestedBidder')
            return 'Budgivning med BankID';
          else return 'Mina sidor';
        default:
          return item;
      }
    },
    translateWhat(item) {
      switch (item) {
        case 'Read':
          return 'Läst';
        case 'Open':
          return 'Öppnat';
        case 'OpenDocument':
          return 'Öppnat dokument';
        case 'Answered':
          return 'Svarat';
        case 'View':
          return 'Inloggning';
        case 'Email':
          return 'E-post';
        case 'FormSent':
          return 'Inskickat formulär';
        case 'Sent':
          return 'Skickad';
        default:
          return item;
      }
    },
    translateContactRole(item) {
      switch (item) {
        case 'ViewingParticipant':
          return 'Visningsdeltagare';
        case 'Buyer':
          return 'Köpare';
        case 'Seller':
          return 'Säljare';
        case 'InterestedBidder':
          return 'Budgivare';
        case 'Interested':
          return 'Spekulant';
        default:
          return item;
      }
    },
    translateValue(item) {
      switch (item) {
        case 'ObjectDescription':
          return 'Objektsbeskrivning';
        case 'BrokerAd':
          return 'Annons fri värdering';
        case 'S&P':
          return 'Annons S&P bolån';
        case 'Document':
          return 'Dokument';
        case 'Home':
          return 'Via hemsidan';
        case 'Buy':
          return 'Direktlänk köpare';
        case 'Sell':
          return 'Direktlänk säljare';
        case 'Bidding':
          return 'budgivningslänk';
        case 'Answered':
          return 'Besvarat';
        case 'Sent':
          return 'Skickat';
        case 'DELIVERED':
          return 'Skickat';
        case 'EXPIRED':
          return 'Gick ej att skicka';
        case 'UNDELIVERABLE':
          return 'Ej möjligt att leverera';
        case 'REJECTED':
          return 'Avvisat';
        case 'Email':
          return 'E-post';
        default:
          return item;
      }
    },

    translateDate(item) {
      return item.slice(0, 10);
    },
    translateDateTime(item) {
      return item.slice(0, 16).replace('T', ' ');
    },

    calculateStats() {
      this.totalLogins = this.events.length;
      console.log('hur ånga', this.totalLogins);
      //filtrera fram unika contactId
      const uniqueLoginsSet = new Set(
        this.events.map((user) => user.ContactId)
      );
      this.uniqueLogins = uniqueLoginsSet.size;

      if (this.eventType.value == 'MinaSidor') {
        // Counting total users with status = "Buy"
        this.totalBuyers = this.events.filter(
          (user) => user.ContactRole === 'Buyer'
        ).length;

        // Using a Set to get unique users based on the 'id' property with status = "Buy"
        const uniqueBuyUsersSet = new Set(
          this.events
            .filter(
              (user) =>
                user.ContactRole === 'Buyer' && user.ContactId !== undefined
            ) // Add a check for 'id'
            .map((user) => user.ContactId)
        );

        // Calculating the number of unique users with status = "Buy"
        this.uniqueBuyers = uniqueBuyUsersSet.size;

        // Counting total users with status = "Sell"
        this.totalSellers = this.events.filter(
          (user) => user.ContactRole === 'Seller'
        ).length;

        // Using a Set to get unique users based on the 'id' property with status = "Buy"
        const uniqueSellUsersSet = new Set(
          this.events
            .filter(
              (user) =>
                user.ContactRole === 'Seller' && user.ContactId !== undefined
            ) // Add a check for 'id'
            .map((user) => user.ContactId)
        );

        // Calculating the number of unique users with status = "Buy"
        this.uniqueSellers = uniqueSellUsersSet.size;
      }
    },
    rowClick(item) {
      this.currentEvent = item;

      this.eventDialog = true;
    },
    closeComment() {
      this.eventDialog = false;
      this.currentEvent = [];
      this.commentSurveyType = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eventCard {
  width: 100%;
  padding: 30px;
}

.eventDialog {
  padding: 20px;
}

.tbHeader {
  width: 240px;
}
.tbData {
  width: 60px;
}
</style>
