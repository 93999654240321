import {
  ESTATE,
  SERVICE_ORDER,
  BROKER_ESTATES,
  SUPPORT_INFO,
  NPS_DATA,
  USER_INFO,
  LOADING,
  SUB_LOADER,
  ESTATE_SETTINGS_VISIBILITY,
  IMG_URL,
} from './types.js';

import azureApi from '@/api/azureApi.js';
import get from 'lodash/get';
import demo from '@/demo_data.js';

export default {
  async clear_all({ commit }) {
    commit(ESTATE, null);

    commit(SERVICE_ORDER, null);
    commit(SUPPORT_INFO, null);
    commit(BROKER_ESTATES, null);

    commit(USER_INFO, null);
    commit(NPS_DATA, null);
    commit(IMG_URL, null);
  },
  async clear_SO({ commit }) {
    commit(SERVICE_ORDER, null);
    commit(ESTATE, null);
  },

  async fetch_broker_info({ commit }, url) {
    var obj = new Object();

    obj.url = url;

    var answ = await azureApi.postBoostad('AdminPage', obj);
    //skicka tillbaka hela serviceorder?
    commit(SERVICE_ORDER, answ);
    commit(LOADING, false);
    if (answ.session) {
      return answ.session;
    } else if (answ && answ.userId == 'HAN5C5BF52EB232453B9392AF3E00049F4F') {
      //fix för att EO testanvändare inte har mobilnr.
      // om det strular för EO testanvändare lägg till telnr på ovanstående id i vitec_master.users +46705367608 och på statistikservern vitec.dbo.Brokers (troligen den senare den försvunnit från)
      //eller i mspecs.users
      console.log(
        'om det strular kanske bostadsguidens telnr försvunnit från vår databas'
      );

      return '611EF442-0DEA-48B9-B142-5F588AAF7CAD';
    } else return 'Ingen session';
  },
  async fetch_quick_info({ commit, state }, url) {
    const tmr = window.setTimeout(() => commit(LOADING, true), 2000);
    var obj = new Object();
    obj.url = url;
    var answ = null;
    try {
      answ = await azureApi.postBoostad('AdminPage?method=mini', obj);
      var alreadyfetched = get(state, 'serviceOrder.boostad_admin', null);
      if (!alreadyfetched) {
        //skicka tillbaka hela serviceorder?
        commit(SERVICE_ORDER, answ);
        //commit(LOADING, false);
      }
      commit(USER_INFO, answ);
    } catch (e) {
      console.log('Error fetching quick_user_info', e);
    } finally {
      window.clearTimeout(tmr);
      commit(LOADING, false);
    }
  },

  async getEstate({ state, commit }) {
    var obj = new Object();
    //getter funkar inte?
    var jwtToken = get(state.serviceOrder, 'boostad_admin.Str', '');
    var source = get(state, 'userInfo.source', '');
    if (!source) source = get(state, 'serviceOrder.source', '');
    obj.Source = source;
    var answ = '';
    if (source == 'Vitec') {
      obj.Id = state.serviceOrder.targetId;
      answ = await azureApi.postBackEnd(
        'IBoostadAdmin/GetEstateByExternalIdAsync?updateFromSource=true',
        obj,
        jwtToken
      );
    } else if (source == 'Mspecs') {
      obj.Id = state.serviceOrder.dealId;
      //får inga prenumerationer från mspecs så vi hämtar alltid objektet från dem
      answ = await azureApi.postBackEnd(
        'IBoostadAdmin/GetEstateByExternalIdAsync?updateFromSource=true',
        obj,
        jwtToken
      );
    }

    if (answ && answ.status == 0) return answ;
    answ = answ.data;
    // if there are sellers, then fetch them too before we commit
    //    (replaces the Sellers array that only contains id's with the full seller object)

    if (answ && answ.Sellers) {
      var sellerIds = answ.Sellers;
      answ.Sellers = [];
      await Promise.all(
        sellerIds.map(async (id) => {
          var seller = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            jwtToken
          );
          if (seller && seller.OnBehalfOf && seller.OnBehalfOf.length > 0) {
            await Promise.all(
              seller.OnBehalfOf.map(async (s) => {
                if (s.EstateUid == answ.Uid) {
                  var details = await azureApi.getBackEnd(
                    'IBoostadAdmin/GetContactByUidAsync/' + s.ContactUid,
                    jwtToken
                  );
                  s.Details = details;
                }
              })
            );
          }
          answ.Sellers.push(seller);
        })
      );
    }
    // if there are buyers, then fetch them too before we commit
    //    (replaces the Buyers array that only contains id's with the full buyer object)
    if (answ && answ.Buyers) {
      var buyerIds = answ.Buyers;
      answ.Buyers = [];

      await Promise.all(
        buyerIds.map(async (id) => {
          var buyer = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            jwtToken
          );
          answ.Buyers.push(buyer);
        })
      );
    }

    // if there are bidders, then fetch them too before we commit
    //    (replaces the bidders array that only contains id's with the full buyer object)
    if (answ && answ.Interests) {
      var bidders = [];
      answ.Interests.forEach((interest) => {
        if (interest.IsBiddingParticipant == true)
          bidders.push(interest.ContactUid);
      });

      var bidderIds = bidders;
      answ.Bidders = [];

      await Promise.all(
        bidderIds.map(async (id) => {
          var bidder = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            jwtToken
          );
          answ.Bidders.push(bidder);
        })
      );
    }

    if (answ) {
      commit(ESTATE, answ);
    }

    return answ;
  },

  // async getSeller({ getters }, seller) {
  //   // var jwtToken = get(state.serviceOrder, 'boostad_admin.Str', '');
  //   var answ = await azureApi.getBackEnd(
  //     'IBoostadAdmin/GetContactByUidAsync/' + seller,
  //     getters.jwtToken
  //   );

  //   if (answ) {
  //     return answ;
  //   }
  // },

  async makeDocAvailable({ state, getters }, path) {
    // var jwtToken = get(state.serviceOrder, 'boostad_admin.Str', '');
    path = state.estate.Uid + '/' + path;

    await azureApi.getBackEnd(
      'IBoostadAdmin/MakeDocumentAvailableInStepsAsync/' + path,
      getters.jwtToken
    );
    //felhantering om vi inte får status 200 TODO
    //console.log('docs answ', answ);
  },

  async createLogin({ getters }, Uid) {
    // var jwtToken = get(state, 'userInfo.boostad_admin.Str', '');
    // var jwtToken = get(state.serviceOrder, 'boostad_admin.Str', '');

    var answ = await azureApi.getBackEnd(
      'IBoostadAdmin/ImpersonateContactByUidAsync/' + Uid,
      getters.jwtToken
    );
    if (answ) return answ;
  },

  async changeEstateSettings({ commit, state, getters }, obj) {
    var Uid = get(state, 'estate.Uid', null);
    var answ = await azureApi.postBackEnd(
      'IBoostadAdmin/UpdateEstateSettingsAsync/' + Uid,
      obj,
      getters.jwtToken
    );

    if (answ && answ.statusText == 'OK') {
      commit(ESTATE_SETTINGS_VISIBILITY, obj);
    }
    return answ;
  },

  async getBrokerEstates({ commit, state, getters }, brokerInfo) {
    var obj = new Object();
    var source = get(state, 'userInfo.source', '');
    if (!source) source = get(state, 'serviceOrder.source', '');

    obj.Source = source;
    obj.Id = brokerInfo.userId;

    var answ = await azureApi.postBackEnd(
      'IBoostadAdmin/GetEstatesForBrokerByExternalIdAsync',
      obj,
      getters.jwtToken
    );
    if (answ && answ.data) {
      commit(BROKER_ESTATES, answ.data);
      return answ.data;
    }
  },

  async getLoginUrl({ getters }, path) {
    var answ = await azureApi.getBackEnd(
      'IBoostadAdmin/GetContactEstateLinkAsync/' + path,
      getters.jwtToken
    );
    return answ;
  },

  async saveSupportInfo({ commit }, params) {
    //ta bort?
    commit(SUPPORT_INFO, params);
  },

  async getUserFromSession({ commit }, session) {
    var answ = null;
    answ = await azureApi.getBoostad('AdminPage/' + session);
    //console.log('user not so quick', answ);
    //window.setTimeout(() => {
    commit(USER_INFO, answ);
    commit(LOADING, false);
    // }, 1500);
    return answ;
  },
  //TABORT SMH
  async TempGetUserFromSession({ state, commit }, brokerSystem) {
    var session = state.userInfo.session;
    var answ = null;
    answ = await azureApi.getBoostad(
      'AdminPage/' + session + '?BrokerSystem=' + brokerSystem
    );
    //console.log('user not so quick', answ);
    //window.setTimeout(() => {
    commit(USER_INFO, answ);
    commit(LOADING, false);
    // }, 1500);
    return answ;
  },

  async getQuickInfo({ commit, state }, session) {
    const tmr = window.setTimeout(() => commit(LOADING, true), 2000);
    var answ = null;
    try {
      answ = await azureApi.getBoostad('AdminPage/' + session + '?method=mini');
      var alreadyfetched = get(state, 'userInfo.boostad_admin', null);
      if (!alreadyfetched) {
        commit(USER_INFO, answ);
      }
    } catch (e) {
      console.log('Error fetching quick_user_info', e);
    } finally {
      window.clearTimeout(tmr);
      commit(LOADING, false);
    }
  },

  async getNPSData({ state, commit }, obj) {
    var session = state.userInfo.session;
    if (session) {
      var answ = await azureApi.postBoostad(
        'BrokerNps?session=' + session,
        obj
      );
      // console.log('answ nnps', answ);
      commit(NPS_DATA, answ);
      if (answ.status == 1) {
        if (
          answ.answers == null ||
          (answ.answers && answ.answers.length == '0')
        )
          return 'no answers';
        else return 'true';
      } else if (answ.status == 0) return 'error';
    }
  },
  async npsDemoData({ commit }) {
    //console.log('demo', demo.demo_NPS());
    var data = demo.demo_NPS();
    commit(NPS_DATA, data);
  },

  async changeSubLoader({ commit }, value) {
    commit(SUB_LOADER, value);
  },

  async localEstate({ commit, getters, state }, theestate) {
    var source = get(state, 'userInfo.source', '');
    //kör update from source om det är mspecs eller om vi kommer från manuell synk
    if (source == 'Mspecs' || theestate.manualSync) {
      var obj = new Object();
      obj.Id = theestate.ExternalIDs[0].Id;
      obj.Source = source;
      //får inga prenumerationer från mspecs så vi hämtar alltid objektet från dem
      var answ = await azureApi.postBackEnd(
        'IBoostadAdmin/GetEstateByExternalIdAsync?updateFromSource=true',
        obj,
        getters.jwtToken
      );
      if (answ && answ.status == 0) {
        commit(ESTATE, '');
        return answ;
      }
      theestate = answ.data;
    }

    const _ = require('lodash');
    var estate = _.cloneDeep(theestate);
    if (estate && estate.Sellers) {
      var sellerIds = estate.Sellers;
      estate.Sellers = [];

      await Promise.all(
        sellerIds.map(async (id) => {
          var seller = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            getters.jwtToken
          );
          if (seller && seller.OnBehalfOf && seller.OnBehalfOf.length > 0) {
            await Promise.all(
              seller.OnBehalfOf.map(async (s) => {
                if (s.EstateUid == estate.Uid) {
                  var details = await azureApi.getBackEnd(
                    'IBoostadAdmin/GetContactByUidAsync/' + s.ContactUid,
                    getters.jwtToken
                  );
                  s.Details = details;
                }
              })
            );
          }
          estate.Sellers.push(seller);
        })
      );
    }

    // if there are buyers, then fetch them too before we commit
    //    (replaces the Buyers array that only contains id's with the full buyer object)
    if (estate && estate.Buyers) {
      var buyerIds = estate.Buyers;
      estate.Buyers = [];

      await Promise.all(
        buyerIds.map(async (id) => {
          var buyer = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            getters.jwtToken
          );
          estate.Buyers.push(buyer);
        })
      );
    }
    // if there are bidders, then fetch them too before we commit
    //    (replaces the bidders array that only contains id's with the full buyer object)
    if (estate && estate.Interests) {
      var bidders = [];
      estate.Interests.forEach((interest) => {
        if (interest.IsBiddingParticipant == true)
          bidders.push(interest.ContactUid);
      });

      var bidderIds = bidders;
      estate.Bidders = [];

      await Promise.all(
        bidderIds.map(async (id) => {
          var bidder = await azureApi.getBackEnd(
            'IBoostadAdmin/GetContactByUidAsync/' + id,
            getters.jwtToken
          );
          estate.Bidders.push(bidder);
        })
      );
    }

    commit(ESTATE, estate);
    return { status: 200 };
  },

  async sendLoginLink({}, params) {
    var obj = {
      estateId: params.estateId,
      ContactId: params.contactId,
      ContactRole: params.contactRole,
      EstateStatus: 'Fritext',
      Fritext: params.message,
      Rules: params.rules,
    };
    var svar = await azureApi.postStatsServer(
      '/api/Msg/SendType/LoginLink',
      obj
    );
    //console.log('svar!', svar);
    if (svar.status == 200) return 'ok';
    else if (svar.errorMsg) return svar.errorMsg;
    else return svar.error;
  },
  async sendMsg({}, params) {
    var obj = {
      BrokerId: params.brokerId,
      CellPhone: params.phone_number,
      Fritext: params.message,
    };
    var svar = await azureApi.postStatsServer(
      '/api/Msg/SendType/CustomMsg',
      obj
    );
    if (svar.status == 200) return 'ok';
    else return svar.error;
  },

  async getBrokerImage({ commit }, params) {
    //hämta base64 till pdfgeneratorn
    var url =
      '/api/Image/Get/Img@' +
      params.org.toUpperCase() +
      '@Broker@' +
      params.brokerId +
      '@DataBase64@Url@50@' +
      params.source;

    var answ = await azureApi.getImageServer(url);
    console.log(answ);
    if (answ?.includes('base64')) commit(IMG_URL, answ);
    else
      commit(
        IMG_URL,
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_noimage_wtext.jpg'
      );
  },
  async getImageUrl({ commit }, params) {
    var url =
      '/api/Image/Get/Img@' +
      params.org.toUpperCase() +
      '@Estate@' +
      params.estateID +
      '@Url@W300@' +
      params.source;

    var answ = await azureApi.getImageServer(url);

    if (answ) commit(IMG_URL, answ);
    else
      commit(
        IMG_URL,
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_noimage_wtext.jpg'
      );
  },

  clearImageUrl({ commit }) {
    commit(IMG_URL, null);
  },
  setInitialBiddingImgUrl({ commit }) {
    commit(
      IMG_URL,
      'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_greycomputer.jpg'
    );
  },
  postLogging({}, whyWhat) {
    azureApi.postStatsServer('/api/EventUpsert', whyWhat);
  },
};
