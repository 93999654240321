<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />
      <v-row>
        <v-card flat class="cardStyle">
          <div>
            <!-- Kund, för oss -->
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-row v-if="showCustomerSelector">
                <v-select
                  dense
                  label="Välj kund"
                  outlined
                  :items="customers"
                  item-text="Name"
                  item-value="Org"
                  return-object
                  v-model="selectedCustomer"
                ></v-select>
              </v-row>
              <!-- kontor -->
              <v-row>
                <v-select
                  dense
                  label="Välj kontor"
                  outlined
                  :items="offices(selectedCustomer?.Org)"
                  item-text="Name"
                  item-value="Id"
                  return-object
                  v-model="selectedOffice"
                ></v-select>
              </v-row>

              <!-- Mäklare-->
              <v-row>
                <v-select
                  dense
                  label="Välj mäklare"
                  outlined
                  :items="users"
                  item-text="Name"
                  item-value="Id"
                  return-object
                  v-model="selectedUser"
                ></v-select>
              </v-row>
            </v-col>
            <!--datum -->
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  width="200px"
                  ref="menuFrom"
                  v-model="menuFrom"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      v-model="dateFrom"
                      outlined
                      label="Fr.o.m"
                      append-icon="arrow_drop_down"
                      v-on="on"
                      @click:append="on.click"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    @input="menuFrom = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  ref="menuTo"
                  v-model="menuTo"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      v-model="dateTo"
                      outlined
                      label="T.o.m"
                      append-icon="arrow_drop_down"
                      @click:append="on.click"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    @input="menuTo = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!--slut datum-->
            <!--filter
Dölj filtret tillfälligt fungerar ändå inte, om det inte ska finnas ta bort funktionen också
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-select
                  dense
                  label="Filter"
                  outlined
                  :items="filterMenu"
                  item-text="text"
                  item-value="value"
                  return-object
                  v-model="selectedFilter"
                ></v-select>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6"> </v-col>


            </v-row>
                        slut filter-->
          </div>
        </v-card>
      </v-row>
      <v-row width="100%" v-if="this.selectedFilter.value != 'BudgetReport'">
        <Events :eventType="this.selectedFilter" />
      </v-row>
      <v-row width="100%" v-if="this.selectedFilter.value == 'BudgetReport'">
        <!--Visa items (alla svar)
        <v-switch
          style="margin-right: 30px"
          v-if="this.selectedFilter.value == 'BudgetReport'"
          v-model="showItems"
          label="Visa lista med alla svar"
        ></v-switch>-->
        <FollowUp />
        <Items v-if="this.showItems" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import Events from '@/components/stats/events.vue';
import FollowUp from '@/components/stats/followUp.vue';
import Description from '@/components/description.vue';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import Items from '@/components/stats/event_items.vue';

export default {
  name: 'NPS',
  mixins: [mixin],

  data() {
    return {
      dateFrom: this.getDateFrom(),
      menuFrom: false,
      dateTo: this.getDateTo(),
      menuTo: false,

      header: 'Statistik/Loggar',
      description:
        'Se vilka som läst objektsbeskrivningen och andra utskick (klicka på respektive logg-rad för mer info).',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_statscomputer.jpg',

      selectedCustomer: null,
      showCustomerSelector: false,
      selectedOffice: null,
      selectedUser: null,

      selectedFilter: { text: 'Alla', value: '000000' },
      showItems: false,
    };
  },
  watch: {
    selectedCustomer() {
      this.$store.dispatch('infopingstore/clearUsers');
      //välj alla kontor eller det enda som finns
      this.selectedOffice = this.offices(this.selectedCustomer?.Org)[0];
    },
    customers() {
      //om bara en kund
      if (this.customers?.length == 1) {
        this.selectedCustomer = this.customers[0];
      } else this.showCustomerSelector = true;
    },

    selectedOffice() {
      if (this.selectedOffice?.Id != '000000') {
        this.getUsers();
      } else if (this.selectedOffice?.Id == '000000') {
        this.$store.dispatch('infopingstore/clearUsers');
        this.selectedUser = null;
        this.getStats();
      }
      if (this.users?.length > 0) this.selectedUser = this.users[0];
    },
    selectedUser() {
      if (this.selectedUser != null) {
        this.getStats();
      }
    },

    dateFrom(val) {
      if (val) {
        this.getStats();
      }
    },
    dateTo(val) {
      if (val) {
        this.getStats();
      }
    },
    selectedFilter() {
      this.getStats();
    },
    showItems() {
      this.getStats();
    },
  },
  async beforeMount() {},
  async mounted() {
    await this.getStructure();
  },
  components: { Description, Events, FollowUp, Items },
  computed: {
    ...mapGetters({
      customers: 'infopingstore/statsCustomers',
      offices: 'infopingstore/statsOffices',
      // users: 'infopingstore/statsUsers',
    }),
    users() {
      var users = get(
        this.$store.state.infopingstore,
        'statsBrokers.Companies[0].Customers[0].Users',
        null
      );
      var userslist = cloneDeep(users);
      //sortera
      if (userslist?.length > 0)
        userslist.sort((a, b) =>
          a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
        );
      // lägg till "Alla mäklare" om det är fler än en
      if (userslist?.length > 1)
        userslist.unshift({ Name: 'Alla mäklare', Id: '000000' });
      return userslist;
    },

    session() {
      return get(this.$store.state.azurestore.userInfo, 'session', null); //johan '55E4AA6D-85DB-43AB-9AEE-D6475A10527A'; //
    },
    filterMenu() {
      var menu = [];
      if (this.org == 'SMH') {
        menu = [
          { text: 'Inget filter', value: '000000' },
          { text: 'Inloggningar Budgivning med BankID', value: 'Budgivning' },
          { text: 'Inloggningar Mina sidor', value: 'MinaSidor' },
          { text: 'Lämnat lead', value: 'Lead' },
          { text: 'Läst objektsbeskrivning', value: 'ObjectDescription' },
          { text: 'Läst dokument (Mina sidor)', value: 'OpenDocument' },
          { text: 'Svarat', value: 'Answered' },
          { text: 'Uppföljning', value: 'BudgetReport' },
        ];
      } else {
        menu = [
          { text: 'Inget filter', value: '000000' },
          { text: 'Inloggningar Budgivning med BankID', value: 'Budgivning' },
          { text: 'Inloggningar Mina sidor', value: 'MinaSidor' },
          { text: 'Lämnat lead', value: 'Lead' },
          { text: 'Läst objektsbeskrivning', value: 'ObjectDescription' },
          { text: 'Läst dokument (Visnings-sms)', value: 'Document' },
          { text: 'Läst dokument (Mina sidor)', value: 'OpenDocument' },
          { text: 'Svarat', value: 'Answered' },
        ];
      }

      return menu;
    },
  },
  methods: {
    async getStructure() {
      var obj = { Session: this.session };

      var res = await this.$store.dispatch('infopingstore/statsStructure', obj);

      if (res?.status == 0) {
        this.alertDialogNoCancel('Inget svar', 'Felkod: ' + res.error, () =>
          console.log('user ok')
        );
      }
    },
    async getUsers() {
      var obj = new Object();
      obj.Session = this.session;
      obj.Org = this.selectedCustomer.Org;
      obj.CustomerId = this.selectedOffice.Id;

      var res = await this.$store.dispatch('infopingstore/statsUsers', obj);
      if (res?.status == 1) this.selectedUser = this.users[0];
      else
        this.alertDialogNoCancel(
          'Hittar inga mäklare',
          'Felkod: ' + res.error,
          () => console.log('user ok')
        );
    },
    async getStats() {
      this.$store.dispatch('azurestore/changeSubLoader', true);
      var result = await this.$store.dispatch(
        'infopingstore/statsData',
        await this.statsObj()
      );
      if (
        result?.standard?.status == '0' ||
        result?.meetings?.status == '0' ||
        result?.commission?.status == '0' ||
        result?.compNps?.status == '0' ||
        result?.brokerNps?.status == '0' ||
        result?.sellerNps?.status == '0' ||
        result?.buyerNps?.status == '0'
      ) {
        this.alertDialogNoCancel(
          'Något gick fel',
          'Det gick inte att hämta all data. \n' +
            'Fel: ' +
            (result.standard?.error ||
              result.meetings?.error ||
              result.commission?.error ||
              result.compNps?.error ||
              result.brokerNps?.error ||
              result.sellerNps?.error ||
              result.buyerNps?.error),
          () => console.log('ok')
        );
      }
      this.$store.dispatch('azurestore/changeSubLoader', false);
    },

    async statsObj() {
      var obj = new Object();
      //tömma variablerna? eller inte ha dem globala
      obj.fromDate = this.dateFrom;
      obj.lessDate = this.getDateLess();
      obj.Last = null;
      obj.Session = this.session;
      obj.BrokerSystem = null;
      if (this.selectedOffice?.Id != '000000')
        obj.CustomerId = this.selectedOffice?.Id;
      //om inget customerId skicka inte med org (om man skickar med org när man bara har session funkar det inte, johan får fixa innan vi alltid skickar med org)
      if (obj.CustomerId) obj.Org = this.selectedCustomer.Org;
      //if (this.selectedCustomer?.Org) obj.Org = this.selectedCustomer.Org;
      obj.EstateId = null;
      obj.SubType = null;
      obj.StreetAddress = null;
      if (this.selectedUser?.Id != '000000')
        obj.BrokerId = this.selectedUser?.Id;
      obj.BrokerUid = null;
      obj.ContactId = null;
      obj.ContactRole = null;
      obj.ContactName = null;
      obj.Type = null;
      if (this.selectedFilter.value == 'ObjectDescription')
        obj.Value = this.selectedFilter.value;
      else if (this.selectedFilter.value == 'Document')
        obj.Value = this.selectedFilter.value;
      else if (this.selectedFilter.value == 'Lead')
        obj.What = this.selectedFilter.value;
      else if (this.selectedFilter.value == 'OpenDocument')
        obj.What = this.selectedFilter.value;
      else if (this.selectedFilter.value == 'Answered')
        obj.What = this.selectedFilter.value;
      if (
        this.selectedFilter.value == 'MinaSidor' ||
        this.selectedFilter.value == 'Budgivning'
      ) {
        obj.Type = 'MinaSidor';
        obj.What = 'View';
      }
      obj.Extra = null;
      obj.Rating = null;
      obj.Scale = null;
      obj.Nps = null;
      obj.Ip4 = null;
      obj.SourceId = null;
      obj.SourceName = null;
      obj.SourceNumber = null;
      obj.PhoneNumber = null;
      if (this.selectedFilter.value == 'BudgetReport')
        obj.TypeOfStatistics = this.selectedFilter.value;
      else obj.TypeOfStatistics = 'Events';
      if (obj.TypeOfStatistics == 'Events') obj.WithItems = true;
      else obj.WithItems = this.showItems;
      obj = await this.clean_null_obj(obj);
      return obj;
    },

    async clean_null_obj(obj) {
      return JSON.parse(
        JSON.stringify(obj, (key, value) => {
          if (value !== null) return value;
        })
      );
    },
    getDateTo() {
      var d = new Date();

      d = d.toISOString().slice(0, 10);

      return d;
    },

    getDateFrom() {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      d = d.toISOString().slice(0, 10);

      return d;
    },
    getDateLess() {
      var addDays = function (str, days) {
        var myDate = new Date(str);
        myDate.setDate(myDate.getDate() + parseInt(days));
        return myDate;
      };

      var myDate = addDays(this.dateTo, 1);
      myDate = myDate.toISOString().slice(0, 10);
      return myDate;
    },
  },
};
</script>
<style scoped>
.v-menu__content {
  background-color: var(--calendar-background);
}
</style>
